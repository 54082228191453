/* Object Fit Images */
import objectFitImages from 'object-fit-images';

function initPluginObjectFitImages() {
    if (typeof objectFitImages !== 'undefined') {
        objectFitImages();
    }
}

export { initPluginObjectFitImages };
