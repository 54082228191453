require('popper.js');
require('bootstrap');

require('gsap/TweenMax');
require('gsap/ScrollToPlugin');
require('sticky-kit');
require('./vendor/skylith/skylith.js');
require('./vendor/skylith/skylith-init.js');

import { jarallax } from 'jarallax'
// import 'hammerjs';
// import 'imagesloaded';
// import 'isotope-layout';
// import 'justifiedGallery';
// import 'keymaster';
// import 'nanoscroller';
// import 'photoswipe'

// nkSlider jarallax hack from nk
$('.nk-slider').each(function() {
    let $this = $(this);
    const $slides = $this.find('.nk-slider-bg-image');
    jarallax($this, {
        type: 'custom',
        imgSrc: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
        onScroll: function (calc) {
            $slides.css({
                'background-position-y': 'calc(50% + ' + (calc.afterTop * 0.3) + 'px)',
            });
        },
    });
});
